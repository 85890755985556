import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
} from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";

import MultiSelect from "../../components/MultiSelect";

export default function Fields({ control, register, errors }) {
  const { autocompleteProps } = useAutocomplete({
    resource: "games",
  });
  const { options: rewards } = useSelect({
    resource: "item-types",
    optionLabel: "name",
    optionValue: "id",
  });
  const { options: loots } = useSelect({
    resource: "loots",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <Controller
        control={control}
        name="game"
        rules={{ required: "Game is required" }}
        render={({ field }) => (
          <Autocomplete
            {...autocompleteProps}
            {...field}
            value={field.value ? field.value : ""}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            getOptionLabel={(item) => {
              return item.name ? item.name : "";
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.id === value.id
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Game"
                margin="normal"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
                required
              />
            )}
          />
        )}
      />
      <TextField
        {...register("code", { required: "Reward Code is required" })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.code}
        helperText={errors.code?.message}
        margin="normal"
        required
        fullWidth
        id="code"
        label="Reward Code"
        name="code"
        autoFocus
      />
      <TextField
        {...register("currency")}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.currency}
        helperText={errors.currency?.message}
        type="number"
        margin="normal"
        required
        fullWidth
        id="currency"
        label="Reward Currency"
        name="currency"
        autoFocus
      />
      <Controller
        control={control}
        name="rewards"
        render={({ field }) => (
          <MultiSelect
            name="Item Rewards"
            options={rewards}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="loots"
        render={({ field }) => (
          <MultiSelect
            name="Loot Rewards"
            options={loots}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
            }}
          />
        )}
      />
    </Box>
  );
}
