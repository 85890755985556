import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import { Grid } from "@pankod/refine-mui";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelect({
  name,
  options,
  onChange,
  value,
  variant,
}) {
  const [selected, setSelected] = useState(value ? value : []);

  useEffect(() => {
    if (value && value.length > 0) {
      if (value[0].id) setSelected(value.map((o) => o.id));
      else setSelected(value);
    }
  }, [value]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value);

    setSelected(value);
    if (onChange) onChange(value);
  };

  const selectedFull = options.filter((option) =>
    selected.includes(option.value)
  );

  return (
    <TextField
      label={name}
      fullWidth
      select
      multiple
      value={selected}
      variant={variant}
      MenuProps={MenuProps}
      SelectProps={{
        multiple: true,
        onChange: handleChange,
        renderValue: () => _.map(selectedFull, "label").join(", "),
      }}
      sx={{ my: 2 }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          <Checkbox checked={selected.find((o) => o == option.value) != null} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </TextField>
  );
}
