import { useMemo } from "react";
import { usePermissions } from "@pankod/refine-core";
import { toast } from "react-toastify";

import {
  Typography,
  useDataGrid,
  DataGrid,
  TagField,
  DateField,
  List,
  EditButton,
  DeleteButton,
  Stack,
} from "@pankod/refine-mui";

export default function GamesList() {
  const { data } = usePermissions();

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Game Name", flex: 1, minWidth: 200 },
      {
        field: "api_key",
        headerName: "API KEY",
        minWidth: 100,
        flex: 1,
        renderCell: function render(params) {
          return (
            <TagField
              value={params.row.api_key}
              onClick={() => {
                navigator.clipboard.writeText(params.row.api_key);
                toast("Copied to clipboard");
              }}
            />
          );
        },
      },
      {
        field: "requests",
        headerName: "Requests",
        minWidth: 50,
        flex: 1,
        renderCell: function render(params) {
          return <Typography>{params.row.requests}</Typography>;
        },
      },
      {
        field: "players",
        headerName: "Players",
        minWidth: 50,
        flex: 1,
        renderCell: function render(params) {
          return <Typography>{params.row.players.length}</Typography>;
        },
      },
      {
        field: "createdAt",
        headerName: "Created",
        minWidth: 220,
        renderCell: function render(params) {
          return <DateField format="LLL" value={params.row.createdAt} />;
        },
      },
      ,
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();

  return (
    <List
      resource="games"
      canCreate={data == "admin"}
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Games</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
