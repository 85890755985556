import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  Grid,
} from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";

import MultiSelect from "../../components/MultiSelect";

export default function Fields({ control, register, errors }) {
  const { autocompleteProps } = useAutocomplete({
    resource: "games",
  });
  const { autocompleteProps: items } = useAutocomplete({
    resource: "item-types",
  });

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <Controller
        control={control}
        name="game"
        rules={{ required: "Game is required" }}
        render={({ field }) => (
          <Autocomplete
            {...autocompleteProps}
            {...field}
            value={field.value ? field.value : ""}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            getOptionLabel={(item) => {
              return item.name ? item.name : "";
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.id === value.id
            }
            renderInput={(params) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                {...params}
                label="Game"
                margin="normal"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
                required
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name="item_type"
        rules={{ required: "Item is required" }}
        render={({ field }) => (
          <Autocomplete
            {...items}
            {...field}
            value={field.value ? field.value : ""}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            getOptionLabel={(item) => {
              return item.name ? item.name : "";
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.id === value.id
            }
            renderInput={(params) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                {...params}
                label="Item"
                margin="normal"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
                required
              />
            )}
          />
        )}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        {...register("price", {
          required: "Price is required",
        })}
        error={!!errors?.price}
        helperText={errors.price?.message}
        margin="normal"
        required
        fullWidth
        id="price"
        label="Item Price"
        name="price"
        type="number"
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        {...register("amount", {
          required: "Amount is required",
        })}
        error={!!errors?.amount}
        helperText={errors.amount?.message}
        defaultValue={1}
        margin="normal"
        required
        fullWidth
        id="amount"
        label="Item Amount"
        name="amount"
        type="number"
      />
    </Box>
  );
}
