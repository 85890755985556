import { Refine, usePermissions, Authenticated } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider, AuthHelper } from "@pankod/refine-strapi-v4";

import axios from "utils/axios";
import { API_URL } from "./constants";
import { ToastContainer } from "react-toastify";
import { authProvider } from "./authProvider";

import {
  ErrorComponent,
  ReadyPage,
  CssBaseline,
  createTheme,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
} from "@pankod/refine-mui";

import CustomLayout from "./components/CustomLayout";
import RegisterPage from "components/auth/RegisterPage";
import LoginPage from "./components/auth/LoginPage";
import PasswordForgot from "./components/auth/PasswordForgot";
import PasswordReset from "./components/auth/PasswordReset";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";

import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import GameList from "./pages/games/GameList";
import GameCreate from "./pages/games/GameCreate";
import GameEdit from "./pages/games/GameEdit";

import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import NewsList from "./pages/news/List";
import NewsCreate from "./pages/news/Create";
import NewsEdit from "./pages/news/Edit";

import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ItemsList from "./pages/items/ItemList";
import ItemCreate from "./pages/items/ItemCreate";
import ItemEdit from "./pages/items/ItemEdit";

import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import RewardsList from "./pages/rewards/List";
import RewardCreate from "./pages/rewards/Create";
import RewardEdit from "./pages/rewards/Edit";

import StarRoundedIcon from "@mui/icons-material/StarRounded";
import LootList from "./pages/loot/LootList";
import LootCreate from "./pages/loot/LootCreate";
import LootEdit from "./pages/loot/LootEdit";

import QrCode2Icon from "@mui/icons-material/QrCode2";
import CouponsList from "./pages/coupons/List";
import CouponsCreate from "./pages/coupons/Create";
import CouponsEdit from "./pages/coupons/Edit";

import LocalMallIcon from "@mui/icons-material/LocalMall";
import ShopItemsList from "./pages/shop-items/List";
import ShopItemsCreate from "./pages/shop-items/Create";
import ShopItemsEdit from "./pages/shop-items/Edit";

import ShopLootsList from "./pages/shop-loots/List";
import ShopLootsCreate from "./pages/shop-loots/Create";
import ShopLootsEdit from "./pages/shop-loots/Edit";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShopsList from "./pages/shops/List";
import ShopsCreate from "./pages/shops/Create";
import ShopsEdit from "./pages/shops/Edit";

import AttributionRoundedIcon from "@mui/icons-material/AttributionRounded";
import ResourceList from "pages/player-resources/List";
import ResourceCreate from "pages/player-resources/Create";
import ResourceEdit from "pages/player-resources/Edit";

import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import ProgressionsList from "pages/progressions/List";
import ProgressionsCreate from "pages/progressions/Create";
import ProgressionsEdit from "pages/progressions/Edit";

import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ChallengeList from "pages/challenges/List";
import ChallengeCreate from "pages/challenges/Create";
import ChallengeEdit from "pages/challenges/Edit";

import LeaderboardIcon from "@mui/icons-material/Leaderboard";

import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import LeaderboardList from "pages/leaderboards/List";
import LeaderboardCreate from "pages/leaderboards/Create";
import LeaderboardEdit from "pages/leaderboards/Edit";

export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#8d99ae",
    },
    secondary: {
      main: "#2b2d42",
    },
    info: {
      main: "#edf2f4",
    },
  },
};

const theme = createTheme(themeOptions);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          routerProvider={{
            ...routerProvider,
            routes: [
              {
                path: "/register",
                element: <RegisterPage />,
                layout: false,
              },
              {
                element: <PasswordForgot />,
                path: "/forgot-password",
                layout: false,
              },
              {
                element: <PasswordReset />,
                path: "/reset-password",
                layout: false,
              },
              {
                element: (
                  <Authenticated>
                    <Settings />
                  </Authenticated>
                ),
                path: "/settings",
                layout: true,
              },
            ],
          }}
          resources={[
            {
              name: "games",
              icon: <VideogameAssetIcon />,
              list: GameList,
              create: GameCreate,
              edit: GameEdit,
              options: {
                alwaysOn: true,
              },
            },
            {
              name: "Resources",
              icon: <StarRoundedIcon sx={{ color: "yellow" }} />,
            },
            {
              parentName: "Resources",
              name: "player-resource-types",
              icon: <AttributionRoundedIcon />,
              options: { label: "Player Resources" },
              list: ResourceList,
              create: ResourceCreate,
              edit: ResourceEdit,
            },
            {
              parentName: "Resources",
              name: "item-types",
              icon: <VpnKeyIcon />,
              options: { label: "Items" },
              list: ItemsList,
              create: ItemCreate,
              edit: ItemEdit,
            },
            {
              parentName: "Resources",
              name: "loots",
              icon: <StarRoundedIcon />,
              options: { label: "Loot" },
              list: LootList,
              create: LootCreate,
              edit: LootEdit,
            },
            {
              name: "Shops",
              icon: (
                <ShoppingCartIcon
                  sx={{
                    color: "yellow",
                  }}
                />
              ),
            },
            {
              parentName: "Shops",
              name: "shop-items",
              icon: <LocalMallIcon />,
              options: { label: "Shop Items" },
              list: ShopItemsList,
              create: ShopItemsCreate,
              edit: ShopItemsEdit,
            },
            {
              parentName: "Shops",
              name: "shop-loots",
              icon: <LocalMallIcon />,
              options: { label: "Shop Loots" },
              list: ShopLootsList,
              create: ShopLootsCreate,
              edit: ShopLootsEdit,
            },
            {
              parentName: "Shops",
              name: "shops",
              icon: <ShoppingCartIcon />,
              options: { label: "Shops" },
              list: ShopsList,
              create: ShopsCreate,
              edit: ShopsEdit,
            },
            {
              name: "Competition",
              icon: (
                <LeaderboardIcon
                  sx={{
                    color: "yellow",
                  }}
                />
              ),
            },
            {
              parentName: "Competition",
              name: "leaderboards",
              icon: <FormatListNumberedIcon />,
              options: { label: "Leaderboards" },
              list: LeaderboardList,
              create: LeaderboardCreate,
              edit: LeaderboardEdit,
            },
            {
              name: "progression-types",
              icon: <TimelineRoundedIcon />,
              options: { label: "Progressions" },
              list: ProgressionsList,
              create: ProgressionsCreate,
              edit: ProgressionsEdit,
            },
            {
              name: "challenge-types",
              icon: <SportsEsportsIcon />,
              options: { label: "Challenges" },
              list: ChallengeList,
              create: ChallengeCreate,
              edit: ChallengeEdit,
            },
            {
              name: "news",
              icon: <NewspaperRoundedIcon />,
              list: NewsList,
              create: NewsCreate,
              edit: NewsEdit,
            },
            {
              name: "daily-reward-configs",
              icon: <Inventory2RoundedIcon />,
              options: { label: "Daily Rewards" },
              list: RewardsList,
              create: RewardCreate,
              edit: RewardEdit,
            },
            {
              name: "coupons",
              icon: <QrCode2Icon />,
              options: { label: "Reward Codes" },
              list: CouponsList,
              create: CouponsCreate,
              edit: CouponsEdit,
            },
          ]}
          authProvider={authProvider}
          dataProvider={DataProvider(API_URL + "/api", axios)}
          notificationProvider={notificationProvider}
          Title={({ collapsed }) => (
            <div style={{ height: collapsed ? 50 : 150 }}>
              {collapsed && (
                <img src="/logo_sm.png" alt="Logo" style={{ width: "50px" }} />
              )}
              {!collapsed && (
                <img src="/logo.png" alt="Logo" style={{ width: "150px" }} />
              )}
            </div>
          )}
          Layout={CustomLayout}
          DashboardPage={Dashboard}
          ReadyPage={ReadyPage}
          LoginPage={LoginPage}
          catchAll={<ErrorComponent />}
        />
      </RefineSnackbarProvider>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
