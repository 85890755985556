import { useState, useEffect } from "react";
import { TextField, Grid, Card, Button, IconButton } from "@pankod/refine-mui";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Controller,
  useFieldArray,
  useWatch,
} from "@pankod/refine-react-hook-form";
import AutoInput from "components/AutoInput";

export default function ({
  name,
  label,
  control,
  generateFields,
  elevation,
  cardRadius,
  justify,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  const dataControl = useWatch({
    name: name,
    control,
  });

  return (
    <Card elevation={elevation} sx={{ padding: 2, margin: 2, width: "100%" }}>
      {label && <h2>{label}</h2>}
      <Grid container>
        {fields.map((item, i) => {
          const watchedItem = dataControl ? dataControl[i] : item;
          return (
            <Card
              key={item.id}
              elevation={5}
              sx={{
                padding: 4,
                margin: 2,
                width: "100%",
                borderRadius: cardRadius,
              }}
            >
              <div style={{ position: "relative" }}>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  sx={{
                    position: "absolute",
                    top: -20,
                    right: -20,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      remove(i);
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Grid>
              </div>
              <Grid item xs={12} container spacing={2} justifyContent={justify}>
                {generateFields.map((f, index) => {
                  if (
                    watchedItem &&
                    (!f.condition ||
                      (f.condition &&
                        Object.entries(watchedItem).find(
                          (entry) => entry[0] == f.condition.watch
                        ) &&
                        Object.entries(watchedItem).find(
                          (entry) => entry[0] == f.condition.watch
                        )[1] == f.condition.isEqual))
                  )
                    return (
                      <Controller
                        key={index}
                        render={({ field }) => <AutoInput {...f} {...field} />}
                        name={`${name}.${i}.${f.name}`}
                        control={control}
                      />
                    );
                })}
              </Grid>
            </Card>
          );
        })}
        <div>
          <Button
            onClick={() => {
              let newObj = {};
              generateFields.map((field) => {
                newObj[field.name] = "";
              });
              append(newObj);
            }}
          >
            <span>+</span> Add new
          </Button>
        </div>
      </Grid>
    </Card>
  );
}
