import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
  Edit,
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
} from "@pankod/refine-mui";

import UploadZone from "components/UploadZone";

export default function ItemEdit() {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    setError,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const { autocompleteProps } = useAutocomplete({
    resource: "games",
  });

  const imageInput = watch("image");

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <UploadZone
          register={register}
          errors={errors}
          setError={setError}
          setValue={setValue}
          imageInput={imageInput}
          required
        />

        <Controller
          control={control}
          name="game"
          rules={{ required: "Game is required" }}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteProps}
              {...field}
              value={field.value ? field.value : ""}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => {
                return item.name ? item.name : "";
              }}
              isOptionEqualToValue={(option, value) =>
                value === undefined || option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Game"
                  margin="normal"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                />
              )}
            />
          )}
        />

        <TextField
          {...register("title", { required: "News title is required" })}
          InputLabelProps={{ shrink: true }}
          error={!!errors?.title}
          helperText={errors.title?.message}
          margin="normal"
          required
          fullWidth
          id="title"
          label="News Title"
          name="title"
          autoFocus
        />

        <TextField
          {...register("content", { required: "News content is required" })}
          InputLabelProps={{ shrink: true }}
          error={!!errors?.content}
          helperText={errors.content?.message}
          margin="normal"
          required
          fullWidth
          id="content"
          label="News content"
          name="content"
          multiline
          rows={4}
        />

        <TextField
          {...register("published", {
            required: "News publishing date is required",
          })}
          InputLabelProps={{ shrink: true }}
          error={!!errors?.published}
          helperText={errors.published?.message}
          type="date"
          margin="normal"
          required
          fullWidth
          id="published"
          label="News publishing date"
          name="published"
        />
      </Box>
    </Edit>
  );
}
