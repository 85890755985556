import { Controller } from "@pankod/refine-react-hook-form";
import { Switch, Typography, Grid } from "@pankod/refine-mui";

export default function SwitchProperty({ label, name, required, control }) {
  return (
    <Grid container justifyContent="space-around" alignItems="center">
      <Typography>{label}</Typography>
      <Controller
        control={control}
        name={name}
        rules={{ required: required ? `${label} is required` : false }}
        defaultValue={"false"}
        render={({ field }) => {
          if (typeof field.value == "boolean") field.onChange(field.value + "");
          return (
            <Switch
              {...field}
              value={field.value + ""}
              defaultValue={"false"}
              defaultChecked={"false"}
              checked={
                typeof field.value == "boolean"
                  ? field.value
                  : field.value == "true"
                  ? true
                  : false
              }
              onChange={(_, val) => {
                field.onChange(val + "");
              }}
              required
            />
          );
        }}
      />
    </Grid>
  );
}
