import { toast } from "react-toastify";

export const normalizeData = (data) => {
  // following code credits goes to roelbeerens see: https://forum.strapi.io/t/discussion-regarding-the-complex-response-structure-for-rest-graphql-developer-experience/13400/9
  const isObject = (data) =>
    Object.prototype.toString.call(data) === "[object Object]";

  const flatten = (data) => {
    if (!data.attributes) return data;

    return {
      id: data.id,
      ...data.attributes,
    };
  };

  if (Array.isArray(data)) {
    return data.map((item) => normalizeData(item));
  }

  if (isObject(data)) {
    if (Array.isArray(data.data)) {
      data = [...data.data];
    } else if (isObject(data.data)) {
      data = flatten({ ...data.data });
    } else if (data.data === null) {
      data = null;
    } else {
      data = flatten(data);
    }

    for (const key in data) {
      data[key] = normalizeData(data[key]);
    }

    return data;
  }

  return data;
};

export const filterDataGrid = (dataGridProps) => {
  const gameID = localStorage.getItem("game");

  if (!gameID) {
    toast.error("Please select a game first!");
    return;
  }

  let filteredDataProps = dataGridProps;
  filteredDataProps.rows = dataGridProps.rows.filter(
    (row) => row.game.id == gameID
  );
  return filteredDataProps;
};
