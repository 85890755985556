import { useMemo } from "react";
import { usePermissions } from "@pankod/refine-core";
import { toast } from "react-toastify";

import {
  Typography,
  useDataGrid,
  DataGrid,
  TagField,
  DateField,
  List,
  EditButton,
  DeleteButton,
  Stack,
} from "@pankod/refine-mui";

import { filterDataGrid } from "utils/utils";

export default function ResourceList() {
  const { data } = usePermissions();

  const columns = useMemo(
    () => [
      {
        field: "loot_type",
        headerName: "Loot",
        minWidth: 250,
        flex: 1,
        renderCell: function render({ row }) {
          return row.loot_type.name;
        },
      },
      {
        field: "price",
        headerName: "Price",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "amount",
        headerName: "Amount",
        minWidth: 100,
        flex: 1,
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();

  const filteredDataProps = filterDataGrid(dataGridProps);

  return (
    <List
      resource="shop-loots"
      canCreate={data === "admin"}
      createButtonProps={{ size: "small" }}
      cardHeaderProps={{
        title: <Typography variant="h5">Shop Loots</Typography>,
      }}
    >
      <DataGrid
        {...filteredDataProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
