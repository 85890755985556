import { useForm } from "@pankod/refine-react-hook-form";
import { Edit } from "@pankod/refine-mui";
import Fields from "./Fields";

export default function ItemEdit() {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    control,
    watch,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Fields
        control={control}
        register={register}
        errors={errors}
        watch={watch}
        setError={setError}
        setValue={setValue}
      />
    </Edit>
  );
}
