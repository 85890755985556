import { Create } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import Fields from "./Fields";

export default function ResourceCreate() {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Fields
        control={control}
        register={register}
        errors={errors}
        watch={watch}
        setError={setError}
        setValue={setValue}
      />
    </Create>
  );
}
