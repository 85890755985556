import { useMemo } from "react";
import { usePermissions } from "@pankod/refine-core";
import { toast } from "react-toastify";

import {
  Typography,
  useDataGrid,
  DataGrid,
  Grid,
  Button,
  DateField,
  List,
  EditButton,
  DeleteButton,
  Stack,
} from "@pankod/refine-mui";

import { filterDataGrid } from "utils/utils";

import { useNavigate } from "@pankod/refine-react-router-v6";

// calendar
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import moment from "moment";

const localizer = momentLocalizer(moment);

export default function ResourceList() {
  const { data } = usePermissions();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "entries",
        headerName: "Entries",
        minWidth: 100,
        flex: 1,
        renderCell: function render({ row }) {
          return <Typography>{row.entries?.length || 0}</Typography>;
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
      },
    });

  const filteredDataProps = filterDataGrid(dataGridProps);

  return (
    <List
      resource="leaderboards"
      canCreate={data === "admin"}
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Leaderboards</Typography>,
      }}
    >
      <DataGrid
        {...filteredDataProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
