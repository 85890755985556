import {
  Box,
  TextField,
  Card,
  useAutocomplete,
  Grid,
} from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";

import CustomAutoComplete from "components/CustomAutoComplete";
import MultiSelect from "components/MultiSelect";
import SwitchProperty from "components/SwitchProperty";

export default function Fields({ control, register, errors }) {
  const { autocompleteProps } = useAutocomplete({
    resource: "games",
  });
  const { options: progressions } = useSelect({
    resource: "progression-types",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <CustomAutoComplete
        name="game"
        label="Game"
        required
        control={control}
        autocompleteProps={autocompleteProps}
      />
      <Controller
        control={control}
        name="progressions"
        render={({ field }) => (
          <MultiSelect
            name="Progressions in which the challenge can take place"
            options={progressions}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
            }}
          />
        )}
      />
      <TextField
        {...register("description", {
          required: "Description is required",
        })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.description}
        helperText={errors.description?.message}
        margin="normal"
        required
        fullWidth
        id="description"
        label="Challenge Description"
        name="description"
        autoFocus
      />
      <TextField
        {...register("trigger", {
          required: "Trigger is required",
        })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.trigger}
        helperText={errors.trigger?.message}
        margin="normal"
        required
        fullWidth
        id="trigger"
        label="Challenge Trigger (used to award points)"
        name="trigger"
      />

      <TextField
        {...register("points_required", {
          required: "Points Required, is required, hehe",
        })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.points_required}
        helperText={errors.points_required?.message}
        type="number"
        margin="normal"
        required
        fullWidth
        id="points_required"
        label="Points required to complete the challenge"
        name="points_required"
      />
      <TextField
        {...register("points_awarded", {
          required: "Points Awarded is required",
        })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.points_awarded}
        helperText={errors.points_awarded?.message}
        type="number"
        margin="normal"
        required
        fullWidth
        id="points_awarded"
        label="Points awarded (to the associated progressions) once the challenge is completed"
        name="points_awarded"
      />
      <Grid container justifyContent="space-around" my={2}>
        <Grid item xs={12} md={5}>
          <Card elevation={3}>
            <SwitchProperty
              name="repeatable"
              label="Challenge can be completed multiple times"
              control={control}
              required
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card elevation={3}>
            <SwitchProperty
              name="mandatory"
              label="Challenge is assigned to players on registration"
              control={control}
              required
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
