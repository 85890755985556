import { useMemo } from "react";
import { usePermissions, useMany } from "@pankod/refine-core";

import {
  Typography,
  useDataGrid,
  DataGrid,
  List,
  EditButton,
  DeleteButton,
  Stack,
} from "@pankod/refine-mui";

import { filterDataGrid } from "utils/utils";

export default function ItemList() {
  const { data } = usePermissions();

  const columns = useMemo(
    () => [
      {
        field: "image",
        headerName: "Image",
        width: 150,
        renderCell: (params) => (
          <img
            src={params.value?.url}
            alt={params.value?.name}
            style={{ width: 64 }}
          />
        ),
      },
      { field: "name", headerName: "Loot Name", flex: 1, minWidth: 200 },
      { field: "tag", headerName: "Tag", flex: 1, minWidth: 50 },
      {
        field: "rewards",
        headerName: "Items In The Loot",
        flex: 1,
        minWidth: 50,
        renderCell: function render({ row }) {
          return row.rewards.map((item) => item.name).join(", ");
        },
      },
      {
        field: "min_currency",
        headerName: "Minimum Currency Drop",
        flex: 1,
        minWidth: 50,
      },
      {
        field: "max_currency",
        headerName: "Maximum Currency Drop",
        flex: 1,
        minWidth: 50,
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();

  const filteredDataProps = filterDataGrid(dataGridProps);

  return (
    <List
      resource="loots"
      canCreate={data === "admin"}
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Loot</Typography>,
      }}
    >
      <DataGrid
        {...filteredDataProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
