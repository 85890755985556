import { useMemo } from "react";
import { usePermissions } from "@pankod/refine-core";
import { toast } from "react-toastify";

import {
  Typography,
  useDataGrid,
  DataGrid,
  TagField,
  DateField,
  List,
  EditButton,
  DeleteButton,
  Stack,
} from "@pankod/refine-mui";

import { filterDataGrid } from "utils/utils";

export default function ResourceList() {
  const { data } = usePermissions();

  const columns = useMemo(
    () => [
      {
        field: "image",
        headerName: "Image",
        width: 150,
        renderCell: (params) => (
          <img
            src={params.value?.url}
            alt={params.value?.name}
            style={{ width: 64 }}
          />
        ),
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "tag",
        headerName: "Tag",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "starting_amount",
        headerName: "Starting Amount",
        minWidth: 100,
        flex: 1,
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();

  const filteredDataProps = filterDataGrid(dataGridProps);

  return (
    <List
      resource="player-resource-types"
      canCreate={data === "admin"}
      createButtonProps={{ size: "small" }}
      cardHeaderProps={{
        title: <Typography variant="h5">Player Resources</Typography>,
      }}
    >
      <DataGrid
        {...filteredDataProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
