import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
} from "@pankod/refine-mui";

import CustomAutoComplete from "components/CustomAutoComplete";

export default function Fields({ control, register, errors }) {
  const { autocompleteProps } = useAutocomplete({
    resource: "games",
  });

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <CustomAutoComplete
        name="game"
        label="Game"
        required
        control={control}
        autocompleteProps={autocompleteProps}
      />
      <TextField
        {...register("name", {
          required: "Name is required",
        })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.name}
        helperText={errors.name?.message}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        autoFocus
      />
    </Box>
  );
}
