import React from "react";

import { Grid, Button, TextField, Box, Card } from "@pankod/refine-mui";

import { useLogin } from "@pankod/refine-core";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Login() {
  const { mutate: login } = useLogin();
  const navigate = useNavigate();

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      login({
        username: e.target.email.value,
        password: e.target.password.value,
      });
    } catch (error) {
      toast.error("Login failed");
      console.log(error);
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={11}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Card sx={{ borderBottom: 0, padding: 3, minWidth: 300 }}>
            <Grid container spacing={3} p={3}>
              <Grid item xs={12} container justifyContent="center">
                <img
                  src="/logo.png"
                  alt="UpScale Logo"
                  style={{
                    width: "100%",
                    height: "300px",
                    objectFit: "contain",
                  }}
                />
              </Grid>
              <Grid item container justifyContent="center">
                <h1 level={3}>Sign in your account</h1>
              </Grid>
            </Grid>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              autoComplete="off"
            >
              <TextField
                name="email"
                label="Email"
                rules={[{ required: true }]}
                placeholder="Email"
                required
                defaultValue={params.get("email")}
              />
              <TextField
                name="password"
                label="Password"
                rules={[{ required: true }]}
                placeholder="Password"
                required
                defaultValue={params.get("password")}
              />
              <div style={{ marginBottom: "12px" }}>
                <a
                  style={{
                    float: "right",
                    fontSize: "14px",
                    color: "#00bcd4",
                  }}
                  href={
                    "/register" +
                    (params.get("to") ? "?to=" + params.get("to") : "")
                  }
                >
                  New Here? Create an account!
                </a>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <a
                  style={{
                    float: "right",
                    fontSize: "12px",
                    color: "#00bcd4",
                  }}
                  href="/forgot-password"
                >
                  Forgot password?
                </a>
              </div>
              <Button variant="contained" type="submit">
                Sign in
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
