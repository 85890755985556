import { useState, useEffect } from "react";

import { Box, Input, Typography, Grid, Card } from "@pankod/refine-mui";
import { LoadingButton } from "@mui/lab";

import FileUploadIcon from "@mui/icons-material/FileUpload";

import axios from "utils/axios";

export default function UploadZone({
  register,
  errors,
  setError,
  setValue,
  imageInput,
  required,
}) {
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  useEffect(() => {
    console.log(imageInput);
    if (imageInput && (imageInput.length == 0 || imageInput == "")) {
      setValue("image", null);
    }
  }, [imageInput]);

  const onChangeHandler = async (event) => {
    try {
      setIsUploadLoading(true);

      const formData = new FormData();

      const target = event.target;
      const file = target.files[0];
      const { size, type } = file;

      formData.append("files", file);
      formData.append("field", "image");

      if (type !== "image/jpeg" && type !== "image/png") {
        setError("image", {
          type: "manual",
          message: "File type must be jpeg or png",
        });
        setIsUploadLoading(false);
        return;
      }

      if (size / 1024 / 1024 > 1) {
        setError("image", {
          type: "manual",
          message: "File size must be less than 1MB",
        });
        setIsUploadLoading(false);
        return;
      }

      let res = await axios.post(`/api/upload`, formData, {
        withCredentials: false,
      });

      const imagePaylod = {
        ...res.data[0],
        preview: URL.createObjectURL(file),
      };
      setValue("image", imagePaylod, { shouldValidate: true });

      setIsUploadLoading(false);
    } catch (error) {
      setError("image", { message: "Upload failed. Please try again." });
      setIsUploadLoading(false);
    }
  };

  return (
    <Grid container justifyContent="center" mb={2}>
      <Card elevation={5} sx={{ height: 300, width: 250 }}>
        <Grid container sx={{ width: "100%" }} p={2}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <Box
              component="img"
              sx={{
                width: 200,
                height: 200,
                objectFit: "contain",
              }}
              src={
                imageInput
                  ? imageInput.url || imageInput.preview
                  : "/upload.png"
              }
              alt="Image"
              objectFit="contain"
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="image-input">
              <Input
                id="image-input"
                type="file"
                sx={{ display: "none" }}
                onChange={onChangeHandler}
              />
              <input
                id="file"
                {...register("image", {
                  required: required ? "This field is required" : false,
                })}
                type="hidden"
              />
              <LoadingButton
                fullWidth
                loading={isUploadLoading}
                loadingPosition="end"
                endIcon={<FileUploadIcon />}
                variant="contained"
                component="span"
              >
                Upload
              </LoadingButton>
              <br />
              {errors.image && (
                <Typography variant="caption" color="#fa541c">
                  {errors.image?.message}
                </Typography>
              )}
            </label>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
