import { useForm } from "@pankod/refine-react-hook-form";
import { Edit } from "@pankod/refine-mui";
import Fields from "./Fields";

export default function ResourceEdit() {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Fields control={control} register={register} errors={errors} />
    </Edit>
  );
}
