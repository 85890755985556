import { useState, useEffect } from "react";

import {
  TextField,
  MenuItem,
  Grid,
  Card,
  Typography,
  Autocomplete,
  Divider,
} from "@pankod/refine-mui";
import { Controller } from "@pankod/refine-react-hook-form";
import MultiSelect from "components/MultiSelect";

export default function (field) {
  if (field.type == "title")
    return (
      <Grid item xs={12}>
        <Typography
          variant={field.typographyVariant}
          gutterBottom
          component="div"
        >
          {field.label}
        </Typography>
        <Divider sx={{ mb: 2 }} />
      </Grid>
    );
  if (field.type == "space")
    return <Grid item xs={12} sx={{ height: field.xs || 1 }}></Grid>;
  if (field.type == "divider")
    return (
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={field.xs || 12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
      </Grid>
    );
  if (field.type == "text" || field.type == "number" || field.type == "date")
    return (
      <Grid item xs={field.xs || 12} md={field.md || 12}>
        <TextField
          {...field}
          InputLabelProps={{
            shrink: true,
          }}
          variant={field.fieldVariants || "standard"}
          defaultValue={field.defaultValue}
          fullWidth
          //   label={label}
          //   type={type}
          multiline={field.lines && field.lines > 1}
          rows={field.lines}
        />
      </Grid>
    );
  if (field.type == "select")
    return (
      <Grid item xs={field.xs || 12} md={field.md || 12}>
        <TextField
          {...field}
          InputLabelProps={{
            shrink: true,
          }}
          variant={field.fieldVariants || "standard"}
          defaultValue={field.defaultValue}
          fullWidth
          label={field.label}
          select
        >
          {field.options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  if (field.type == "autocomplete") {
    return (
      <Grid item xs={field.xs || 12} md={field.md || 12}>
        <Autocomplete
          {...field}
          options={field.options.options}
          defaultValue={field.defaultValue}
          onChange={(_, value) => {
            field.onChange(value);
          }}
          getOptionLabel={(item) => {
            return item[field.accessor] ? item[field.accessor] : "";
          }}
          isOptionEqualToValue={(option, value) => {
            return value === undefined || option.id === value.id;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={field.label}
              variant={field.fieldVariants || "standard"}
            />
          )}
        />
      </Grid>
    );
  }
  if (field.type == "multi-select")
    return (
      <Grid item xs={field.xs || 12} md={field.md || 12}>
        <MultiSelect
          name={field.label}
          variant={field.fieldVariants || "standard"}
          options={field.options}
          value={field.value}
          defaultValue={field.defaultValue}
          onChange={(val) => {
            field.onChange(val);
          }}
        />
      </Grid>
    );
}
