import { useEffect } from "react";

import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  Grid,
  Stack,
  Card,
  Button,
} from "@pankod/refine-mui";

import { useWatch } from "@pankod/refine-react-hook-form";

import SwitchProperty from "components/SwitchProperty";
import CustomAutoComplete from "components/CustomAutoComplete";
import JSONArrayEditor from "components/JSONArrayEditor";

export default function Fields({ control, register, errors, setValue }) {
  const { autocompleteProps } = useAutocomplete({
    resource: "games",
  });

  const { autocompleteProps: resources } = useAutocomplete({
    resource: "player-resource-types",
  });

  const { autocompleteProps: items } = useAutocomplete({
    resource: "item-types",
  });

  const { autocompleteProps: loots } = useAutocomplete({
    resource: "loots",
  });

  // base fields
  const fields = [
    {
      md: 2,
      label: "Points To Unlock",
      name: "points",
      required: true,
      type: "number",
    },
    {
      md: 4,
      label: "Unlock Type",
      name: "unlock_type",
      required: true,
      type: "select",
      options: [
        { label: "Currency", value: "currency" },
        { label: "Resource", value: "resource" },
        { label: "Item", value: "item" },
        { label: "Loot", value: "loot" },
      ],
    },
    {
      md: 4,
      label: "Currency",
      name: "reward",
      required: true,
      type: "number",
      condition: {
        watch: "unlock_type",
        isEqual: "currency",
      },
    },
    {
      md: 4,
      label: "Resource",
      name: "reward",
      required: true,
      type: "autocomplete",
      options: resources,
      accessor: "name",
      condition: {
        watch: "unlock_type",
        isEqual: "resource",
      },
    },
    {
      md: 4,
      label: "Item",
      name: "reward",
      required: true,
      type: "autocomplete",
      options: items,
      accessor: "name",
      condition: {
        watch: "unlock_type",
        isEqual: "item",
      },
    },
    {
      md: 4,
      label: "Loot",
      name: "reward",
      required: true,
      type: "autocomplete",
      options: loots,
      accessor: "name",
      condition: {
        watch: "unlock_type",
        isEqual: "loot",
      },
    },
  ];

  // sort values by points
  const data = useWatch({
    control,
    name: "rewards",
  });

  const sortData = () => {
    // if the data and sorted array are different, then sort the array
    if (data && data.length > 0) {
      const sorted = [...data].sort((a, b) => a.points - b.points);
      if (JSON.stringify(data) != JSON.stringify(sorted)) {
        setValue("rewards", sorted);
      }
    }
  };

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <CustomAutoComplete
        name="game"
        label="Game"
        required
        control={control}
        autocompleteProps={autocompleteProps}
      />
      <Card sx={{ p: 2, my: 2 }}>
        <TextField
          InputLabelProps={{ shrink: true }}
          {...register("name", {
            required: "Name is required",
          })}
          error={!!errors?.name}
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Progression Name"
          name="name"
          autoFocus
        />
        <Grid item xs={12} container justifyContent="space-evenly" mt={2}>
          <Grid item xs={4} container alignItems="center">
            <SwitchProperty
              label="Is Premium"
              name="premium"
              required
              control={control}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              InputLabelProps={{ shrink: true }}
              {...register("cost", {
                required: "Cost is required",
              })}
              defaultValue={0}
              error={!!errors?.cost}
              helperText={errors.cost?.message}
              margin="normal"
              required
              fullWidth
              id="cost"
              label="Progression Cost"
              name="cost"
              type="number"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="space-evenly" mt={2}>
          <Grid item xs={4}>
            <TextField
              {...register("from", {
                required: "Progression starting date",
              })}
              InputLabelProps={{ shrink: true }}
              error={!!errors?.from}
              helperText={errors.from?.message}
              type="date"
              margin="normal"
              required
              fullWidth
              id="from"
              label="Progression valid from"
              name="from"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...register("to", {
                required: "Progression ending date",
              })}
              InputLabelProps={{ shrink: true }}
              error={!!errors?.to}
              helperText={errors.to?.message}
              type="date"
              margin="normal"
              required
              fullWidth
              id="to"
              label="Progression valid to"
              name="to"
            />
          </Grid>
        </Grid>
        <JSONArrayEditor
          name="rewards"
          label="Rewards"
          control={control}
          generateFields={fields}
          cardRadius={10}
          justify="space-around"
        />
        <Grid item xs={12} container justifyContent="space-evenly" mt={2}>
          <Button variant="contained" onClick={sortData} fullWidth>
            Sort Rewards
          </Button>
        </Grid>
      </Card>
    </Box>
  );
}
