import { Box, Create } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

import Fields from "./Fields";

export default function ItemCreate() {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    watch,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Fields
          control={control}
          register={register}
          errors={errors}
          watch={watch}
          setError={setError}
          setValue={setValue}
        />
      </Box>
    </Create>
  );
}
