import { AuthProvider } from "@pankod/refine-core";
import { AuthHelper } from "@pankod/refine-strapi-v4";

import { TOKEN_KEY, API_URL } from "./constants";

import axios from "utils/axios";
import { toast } from "react-toastify";

const strapiAuthHelper = AuthHelper(API_URL + "/api");

interface IAxiosResponse {
  data: ILoginResponse;
  status: number;
}

interface ILoginResponse {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
  createdAt: string;
  updatedAt: string;
}

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const { data, status } = await strapiAuthHelper.login(username, password);
    if (status === 200) {
      toast.success(
        "Login successful, welcome back " + data.user.username + "!"
      );
      localStorage.setItem(TOKEN_KEY, data.jwt);
      // set header axios instance
      axios.defaults.headers.common.Authorization = `Bearer ${data.jwt}`;
      // gather me
      const user: IAxiosResponse = await axios.get("/api/users/me");
      localStorage.setItem("user", JSON.stringify(user.data));

      // console.log("Login", data.jwt, user);

      return Promise.resolve();
    }
    return Promise.reject();
  },
  register: async ({ username, email, password }) => {
    const { data, status } = await axios.post("/api/auth/local/register", {
      username,
      email,
      password,
    });
    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      localStorage.setItem("user", JSON.stringify(data.user));
      // set header axios instance
      axios.defaults.headers.common.Authorization = `Bearer ${data.jwt}`;

      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: async () => {
    let auth = localStorage.getItem("user");
    try {
      if (auth) {
        const parsedUser = JSON.parse(auth);
        return Promise.resolve(parsedUser.role.type);
      } else {
        const user: IAxiosResponse = await axios.get("/api/users/me");
        localStorage.setItem("user", JSON.stringify(user));
        return Promise.resolve(user?.data?.role?.type);
      }
    } catch (error) {
      try {
        const user: IAxiosResponse = await axios.get("/api/users/me");
        localStorage.setItem("user", JSON.stringify(user.data));
        return Promise.resolve(user?.data?.role?.type);
      } catch (error) {
        console.log(error);
        return Promise.reject();
      }
    }
  },
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }
    const { data, status } = await strapiAuthHelper.me(token);
    localStorage.setItem("user", JSON.stringify(data));
    if (status === 200) {
      const { id, username, email } = data;
      return Promise.resolve({
        id,
        username,
        email,
      });
    }

    return Promise.reject();
  },
};
