import { useState, useEffect } from "react";

import { Grid, Card, Tabs, Tab, Box, Typography } from "@pankod/refine-mui";

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

import { PieChart } from "react-minimal-pie-chart";
import LineChart from "components/LineChart";

import axios from "axios";
import { TOKEN_KEY, API_URL } from "constants";

// This values are the props in the UI
const amount = "1";
const currency = "GBP";
const style = { layout: "vertical" };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ currency, showSpinner }) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  const axiosInstance = axios.create();
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]);

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              // Your code here after create the order
              axiosInstance.post(`${API_URL}/api/prepare-order`, {
                orderID: orderId,
                orderPrice: Number(amount),
              });
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function () {
            console.log("PAID!", data);
            // Your code here after capture the order
          });
        }}
      />
    </>
  );
};

export default function Dashboard() {
  const [accountData, setAccountData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const axiosInstance = axios.create();
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  const getColor = () => {
    return (
      "hsl(" +
      360 * Math.random() +
      "," +
      (25 + 70 * Math.random()) +
      "%," +
      (55 + 10 * Math.random()) +
      "%)"
    );
  };

  useEffect(() => {
    axiosInstance
      .get(`${API_URL}/api/account`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const gamesGraphData = [];
  accountData?.games?.forEach((game) => {
    gamesGraphData.push({
      title: game.name,
      value: game.players.length,
      color: getColor(),
    });
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return accountData ? (
    <Grid container spacing={5} justifyContent="space-evenly">
      <Grid item md={5} xs={11}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12}>
              <h1>{`You have ${accountData.user.api_calls} API Calls`}</h1>
            </Grid>
            <Grid item xs={12}>
              <h2 style={{ textAlign: "center" }}>Buy 1000 for £1</h2>
              <PayPalScriptProvider
                options={{
                  "client-id":
                    "Acr8lAJWlZuACgo07yY0pwXvsNcZW46ht6_yFB98ywgSnD-RbWrKqbIaW59Kt2J23zN3NOIuLepieYd5",
                  components: "buttons",
                  currency: "GBP",
                }}
              >
                <ButtonWrapper currency={currency} showSpinner={false} />
              </PayPalScriptProvider>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={4} xs={11}>
        <Card sx={{ borderRadius: 10, padding: 5 }}>
          <h3 style={{ textAlign: "center", marginTop: -10 }}>Players</h3>
          <PieChart
            data={gamesGraphData}
            animate
            label={({ dataEntry }) => dataEntry.title + " - " + dataEntry.value}
            labelStyle={(index) => ({
              fontSize: "8px",
              fontFamily: "sans-serif",
            })}
          />
        </Card>
      </Grid>
      <Grid item md={10} xs={11}>
        <Card sx={{ borderRadius: 10, padding: 5 }}>
          <h3 style={{ textAlign: "center", marginTop: -10 }}>Logins</h3>
          <Tabs
            value={selectedTab}
            onChange={(e, newVal) => setSelectedTab(newVal)}
            aria-label="basic tabs example"
          >
            <Tab label="Total" />
            {accountData.games.map((game, index) => (
              <Tab label={game.name} key={index} />
            ))}
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <LineChart data={accountData.logins} label="Logins" height={200} />
          </TabPanel>
          {accountData.games.map((game, index) => (
            <TabPanel value={selectedTab} index={index + 1} key={index}>
              <LineChart data={game.logins} label="Logins" height={200} />
            </TabPanel>
          ))}
        </Card>
      </Grid>
    </Grid>
  ) : (
    <div>Loading...</div>
  );
}
