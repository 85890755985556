import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  Grid,
  Stack,
} from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";

import MultiSelect from "../../components/MultiSelect";

export default function Fields({ control, register, errors }) {
  const { autocompleteProps } = useAutocomplete({
    resource: "games",
  });
  const { options: shopItems } = useSelect({
    resource: "shop-items",
    optionLabel: "name",
    optionValue: "id",
  });
  const { options: lootItems } = useSelect({
    resource: "shop-loots",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Grid component="form" autoComplete="off">
      <Stack spacing={2}>
        <Controller
          control={control}
          name="game"
          rules={{ required: "Game is required" }}
          render={({ field }) => (
            <Autocomplete
              {...autocompleteProps}
              {...field}
              value={field.value ? field.value : ""}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => {
                return item.name ? item.name : "";
              }}
              isOptionEqualToValue={(option, value) =>
                value === undefined || option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...params}
                  label="Game"
                  margin="normal"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                />
              )}
            />
          )}
        />
        <TextField
          {...register("name", {
            required: "Shop starting date",
          })}
          InputLabelProps={{ shrink: true }}
          error={!!errors?.name}
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Shop Name"
          name="name"
          autoFocus
        />
        <Controller
          control={control}
          name="shop_items"
          render={({ field }) => (
            <MultiSelect
              name="Items available in the shop"
              options={shopItems}
              value={field.value}
              onChange={(val) => {
                field.onChange(val);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="shop_loots"
          render={({ field }) => (
            <MultiSelect
              name="Loots available in the shop"
              options={lootItems}
              value={field.value}
              onChange={(val) => {
                field.onChange(val);
              }}
            />
          )}
        />
      </Stack>

      <Grid item xs={12} container justifyContent="space-evenly" mt={2}>
        <Grid item xs={4}>
          <TextField
            {...register("from", {
              required: "Shop starting date",
            })}
            InputLabelProps={{ shrink: true }}
            error={!!errors?.from}
            helperText={errors.from?.message}
            type="date"
            margin="normal"
            required
            fullWidth
            id="from"
            label="Shop valid from"
            name="from"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            {...register("to", {
              required: "Shop ending date",
            })}
            InputLabelProps={{ shrink: true }}
            error={!!errors?.to}
            helperText={errors.to?.message}
            type="date"
            margin="normal"
            required
            fullWidth
            id="to"
            label="Shop valid to"
            name="to"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
