import { useMemo } from "react";
import { usePermissions } from "@pankod/refine-core";
import { toast } from "react-toastify";

import {
  Typography,
  useDataGrid,
  DataGrid,
  TagField,
  DateField,
  List,
  EditButton,
  DeleteButton,
  Stack,
} from "@pankod/refine-mui";

import { filterDataGrid } from "utils/utils";

export default function ResourceList() {
  const { data } = usePermissions();

  const columns = useMemo(
    () => [
      { field: "code", headerName: "Reward Code", flex: 1, minWidth: 200 },
      {
        field: "currency",
        headerName: "Rewarded Currency",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "rewards",
        headerName: "Item Rewards",
        minWidth: 150,
        flex: 1,
        renderCell: function render({ row }) {
          return row.rewards.map((item) => item.name).join(", ");
        },
      },
      {
        field: "loots",
        headerName: "Loot Rewards",
        minWidth: 150,
        flex: 1,
        renderCell: function render({ row }) {
          return row.loots.map((item) => item.name).join(", ");
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();

  const filteredDataProps = filterDataGrid(dataGridProps);

  return (
    <List
      resource="coupons"
      canCreate={data === "admin"}
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Reward Codes</Typography>,
      }}
    >
      <DataGrid
        {...filteredDataProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
