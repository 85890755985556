import { useMemo } from "react";
import { usePermissions } from "@pankod/refine-core";
import { toast } from "react-toastify";

import {
  Typography,
  useDataGrid,
  DataGrid,
  Grid,
  DateField,
  List,
  EditButton,
  DeleteButton,
  Stack,
  Button,
} from "@pankod/refine-mui";

import { useNavigate } from "@pankod/refine-react-router-v6";

import { filterDataGrid } from "utils/utils";

// calendar
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import moment from "moment";

const localizer = momentLocalizer(moment);

export default function ResourceList() {
  const { data } = usePermissions();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "from",
        headerName: "Active From",
        minWidth: 100,
        flex: 1,
        renderCell: function render({ row }) {
          return <DateField value={row.from} />;
        },
      },
      {
        field: "to",
        headerName: "Active Until",
        minWidth: 100,
        flex: 1,
        renderCell: function render({ row }) {
          return <DateField value={row.to} />;
        },
      },
      // {
      //   field: "shop_items",
      //   headerName: "Items in Shop",
      //   minWidth: 250,
      //   flex: 1,
      //   renderCell: function render({ row }) {
      //     return row.shop_items
      //       .map((item) => item.item_type.name + " x " + item.amount)
      //       .join(", ");
      //   },
      // },
      // {
      //   field: "shop_loots",
      //   headerName: "Loots in Shop",
      //   minWidth: 250,
      //   flex: 1,
      //   renderCell: function render({ row }) {
      //     return row.shop_loots
      //       .map((item) => item.loot_type.name + " x " + item.amount)
      //       .join(", ");
      //   },
      // },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();

  const filteredDataProps = filterDataGrid(dataGridProps);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Calendar
          views={["month"]}
          localizer={localizer}
          events={filteredDataProps.rows.map((row) => ({
            ...row,
            title: row.name || row.id,
          }))}
          startAccessor="from"
          endAccessor="to"
          style={{ height: 500 }}
          onSelectEvent={(event) => {
            toast(
              <div>
                <Typography variant="h5">{event.name}</Typography>
                <Typography variant="h6">Validity:</Typography>
                <Typography variant="body1">
                  {event.from} - {event.to}
                </Typography>
                <Typography variant="h6">Items</Typography>
                <Typography variant="body1">
                  {event.shop_items
                    .map((item) => item.item_type.name + " x " + item.amount)
                    .join(", ")}
                </Typography>
                <Typography variant="h6">Loots</Typography>
                <Typography variant="body1">
                  {event.shop_loots
                    .map((item) => item.loot_type.name + " x " + item.amount)
                    .join(", ")}
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  onClick={() => {
                    navigate(`/Shops/shops/edit/${event.id}`);
                    toast.dismiss();
                  }}
                  sx={{ mt: 2 }}
                >
                  Edit
                </Button>
              </div>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <List
          resource="shops"
          canCreate={data === "admin"}
          createButtonProps={{ size: "small" }}
          headerProps={{
            title: <Typography variant="h5">In Game Shops</Typography>,
          }}
        >
          <DataGrid
            {...filteredDataProps}
            columns={columns}
            sortingMode={sortingMode}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            autoHeight
          />
        </List>
      </Grid>
    </Grid>
  );
}
