import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
} from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";

import UploadZone from "components/UploadZone";

export default function Fields({
  control,
  register,
  errors,
  watch,
  setValue,
  setError,
}) {
  const { autocompleteProps } = useAutocomplete({
    resource: "games",
  });

  const imageInput = watch("image");

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <UploadZone
        register={register}
        errors={errors}
        setError={setError}
        setValue={setValue}
        imageInput={imageInput}
      />

      <Controller
        control={control}
        name="game"
        rules={{ required: "Game is required" }}
        render={({ field }) => (
          <Autocomplete
            {...autocompleteProps}
            {...field}
            value={field.value ? field.value : ""}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            getOptionLabel={(item) => {
              return item.name ? item.name : "";
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.id === value.id
            }
            renderInput={(params) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                {...params}
                label="Game"
                margin="normal"
                variant="outlined"
                error={!!errors.game}
                helperText={errors.game?.message}
                required
              />
            )}
          />
        )}
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        {...register("name", {
          required: "Name is required",
        })}
        error={!!errors?.name}
        helperText={errors.name?.message}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Resource Name"
        name="name"
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        {...register("tag", {
          required: "Tag is required",
        })}
        error={!!errors?.tag}
        helperText={errors.tag?.message}
        margin="normal"
        required
        fullWidth
        id="tag"
        label="Resource Tag"
        name="tag"
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        {...register("starting_amount", {
          required: "Starting Amount is required",
        })}
        error={!!errors?.starting_amount}
        helperText={errors.starting_amount?.message}
        margin="normal"
        required
        fullWidth
        id="starting_amount"
        label="Resource Starting Amount for new players"
        name="starting_amount"
        type="number"
      />
    </Box>
  );
}
