import React, { useState } from "react";
import {
  CanAccess,
  useTitle,
  useMenu,
  useTranslate,
  useRouterContext,
  useRefineContext,
  useIsExistAuthentication,
  useLogout,
  useNavigation,
} from "@pankod/refine-core";
import {
  Box,
  Drawer,
  MuiList,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  IconButton,
  Button,
  Select,
  MenuItem,
  Title as DefaultTitle,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@pankod/refine-mui";
import {
  Dashboard,
  ListOutlined,
  ExpandLess,
  ExpandMore,
  ChevronLeft,
  ChevronRight,
  Logout,
  MenuRounded,
  Settings,
} from "@mui/icons-material";
import axiosInstance from "utils/axios";
import { normalizeData } from "utils/utils";

export default function CustomMenu() {
  const [collapsed, setCollapsed] = useState(false);
  const [opened, setOpened] = useState(false);

  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(
    localStorage.getItem("game") || ""
  );

  const isExistAuthentication = useIsExistAuthentication();
  const { mutate: logout } = useLogout();

  const drawerWidth = () => {
    if (collapsed) return 64;
    return 200;
  };

  const t = useTranslate();

  const { Link } = useRouterContext();
  const { push } = useNavigation();
  const { hasDashboard } = useRefineContext();

  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const Title = useTitle();

  const [open, setOpen] = useState(true);

  React.useEffect(() => {
    setOpen((previousOpen) => {
      const previousOpenKeys = Object.keys(previousOpen);
      const uniqueKeys = new Set([...previousOpenKeys, ...defaultOpenKeys]);
      const uniqueKeysRecord = Object.fromEntries(
        Array.from(uniqueKeys.values()).map((key) => [key, true])
      );
      return uniqueKeysRecord;
    });
    // Fetch the game and select it if it's the only one and if it's not already selected
    axiosInstance.get("/api/games").then((res) => {
      let normalizedData = normalizeData(res.data);
      setGames(normalizedData);
      if (normalizedData.length === 1) {
        setSelectedGame(normalizedData[0].id);
        localStorage.setItem("game", normalizedData[0].id);
      } else if (normalizedData.length > 1) {
        setSelectedGame(localStorage.getItem("game") || normalizedData[0].id);
        localStorage.setItem(
          "game",
          localStorage.getItem("game") || normalizedData[0].id
        );
      } else if (normalizedData.length === 0) {
        setSelectedGame("");
      }
    });
  }, [defaultOpenKeys]);

  const RenderToTitle = Title ?? DefaultTitle;

  const handleClick = (key) => {
    setOpen({ ...open, [key]: !open[key] });
  };

  const renderTreeView = (tree, selectedKey) => {
    return tree.map((item) => {
      const { icon, label, route, name, children, parentName, options } = item;
      const isOpen = open[route || ""] || false;

      const isSelected = route === selectedKey;
      const isNested = !(parentName === undefined);

      if (!selectedGame && (!options || !options.alwaysOn)) return;

      if (children.length > 0) {
        return (
          <div key={route}>
            <Tooltip
              title={label ?? name}
              placement="right"
              disableHoverListener={!collapsed}
              arrow
            >
              <ListItemButton
                onClick={() => {
                  if (collapsed) {
                    setCollapsed(false);
                    if (!isOpen) {
                      handleClick(route || "");
                    }
                  } else {
                    handleClick(route || "");
                  }
                }}
                sx={{
                  pl: isNested ? 4 : 2,
                  justifyContent: "center",
                  "&.Mui-selected": {
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    backgroundColor: isSelected
                      ? "primary.main"
                      : "secondary.main",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    justifyContent: "center",
                    minWidth: 36,
                    color: "primary.contrastText",
                  }}
                >
                  {icon ?? <ListOutlined />}
                </ListItemIcon>
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{
                    noWrap: true,
                    fontSize: "14px",
                    fontWeight: isSelected ? "bold" : "normal",
                    color: "#fff",
                  }}
                />
                {isOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </Tooltip>
            {!collapsed && (
              <Collapse in={open[route || ""]} timeout="auto" unmountOnExit>
                <MuiList component="div" disablePadding>
                  {renderTreeView(children, selectedKey)}
                </MuiList>
              </Collapse>
            )}
          </div>
        );
      }

      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list">
          <Tooltip
            title={label ?? name}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
          >
            <ListItemButton
              component={Link}
              to={route}
              selected={isSelected}
              onClick={() => {
                setOpened(false);
              }}
              sx={{
                pl: isNested ? 4 : 2,
                py: isNested ? 1.25 : 1,
                justifyContent: "center",
                "&.Mui-selected": {
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  backgroundColor: isSelected
                    ? "primary.main"
                    : "secondary.main",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  minWidth: 36,
                  color: isSelected ? "secondary.main" : "#fff",
                }}
              >
                {icon ?? <ListOutlined />}
              </ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  noWrap: true,
                  fontSize: "14px",
                  fontWeight: isSelected ? "bold" : "normal",
                  color: isSelected ? "secondary.main" : "#fff",
                }}
              />
            </ListItemButton>
          </Tooltip>
        </CanAccess>
      );
    });
  };

  const gameSelector = () => {
    return (
      <Grid item xs={12} sx={{ p: 2, mb: 2 }}>
        <Select
          size="small"
          variant="standard"
          label="Select Game"
          value={selectedGame || ""}
          onChange={(e) => {
            setSelectedGame(e.target.value);
            localStorage.setItem("game", e.target.value);
            push("/");
          }}
          sx={{ width: "100%" }}
        >
          <MenuItem value="">None</MenuItem>
          {games.map((game) => (
            <MenuItem key={game.id} value={game.id}>
              {game.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    );
  };

  const drawer = (
    <MuiList disablePadding sx={{ mt: 1, color: "primary.contrastText" }}>
      {hasDashboard ? (
        <Tooltip
          title={t("dashboard.title", "Dashboard")}
          placement="right"
          disableHoverListener={!collapsed}
          arrow
        >
          <ListItemButton
            component={Link}
            to="/"
            selected={selectedKey === "/"}
            onClick={() => {
              setOpened(false);
            }}
            sx={{
              pl: 2,
              py: 1,
              "&.Mui-selected": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
                backgroundColor: "transparent",
              },
              justifyContent: "center",
            }}
          >
            <ListItemIcon
              sx={{
                justifyContent: "center",
                minWidth: 36,
                color: "primary.contrastText",
              }}
            >
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              primary={t("dashboard.title", "Dashboard")}
              primaryTypographyProps={{
                noWrap: true,
                fontSize: "14px",
                fontWeight: selectedKey === "/" ? "bold" : "normal",
              }}
            />
          </ListItemButton>
        </Tooltip>
      ) : null}
      {gameSelector()}
      {renderTreeView(menuItems, selectedKey)}
      {isExistAuthentication && (
        <>
          <Tooltip
            title={t("buttons.settings", "Impostazioni")}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
          >
            <ListItemButton
              component={Link}
              to="/settings"
              key="settings"
              sx={{
                justifyContent: "center",
                "&.Mui-selected": {
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                },
                backgroundColor:
                  selectedKey === "/settings" ? "primary.main" : "transparent",
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  minWidth: 36,
                  color:
                    selectedKey === "/settings"
                      ? "primary.contrastText"
                      : "primary.main",
                }}
              >
                <Settings />
              </ListItemIcon>
              <ListItemText
                primary={t("buttons.settings", "Settings")}
                primaryTypographyProps={{
                  noWrap: true,
                  fontSize: "14px",
                  color:
                    selectedKey === "/settings"
                      ? "primary.contrastText"
                      : "primary.main",
                }}
              />
            </ListItemButton>
          </Tooltip>
          <Tooltip
            title={t("buttons.logout", "Logout")}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
          >
            <ListItemButton
              key="logout"
              onClick={() => logout()}
              sx={{ justifyContent: "center" }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  minWidth: 36,
                  color: "error.main",
                }}
              >
                <Logout />
              </ListItemIcon>
              <ListItemText
                primary={t("buttons.logout", "Logout")}
                primaryTypographyProps={{
                  noWrap: true,
                  fontSize: "14px",
                  color: "error.main",
                }}
              />
            </ListItemButton>
          </Tooltip>
        </>
      )}
    </MuiList>
  );

  return (
    <>
      <Box
        sx={{
          width: { xs: drawerWidth() },
          display: {
            xs: "none",
            md: "block",
          },
          transition: "width 0.3s ease",
        }}
      />
      <Box
        component="nav"
        sx={{
          position: "fixed",
          zIndex: 1101,
          width: { sm: drawerWidth() },
          display: "flex",
        }}
      >
        <Drawer
          variant="temporary"
          open={opened}
          onClose={() => setOpened(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: 256,
              bgcolor: "secondary.main",
            },
          }}
        >
          <Box
            sx={{
              height: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <RenderToTitle collapsed={false} />
          </Box>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          PaperProps={{ elevation: 1 }}
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              bgcolor: "secondary.main",
              overflow: "hidden",
              transition: "width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            },
          }}
          open
        >
          <Box
            sx={{
              height: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <RenderToTitle collapsed={collapsed} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {drawer}
          </Box>
          <Button
            sx={{
              background: "rgba(0,0,0,.5)",
              color: "primary.contrastText",
              textAlign: "center",
              borderRadius: 0,
              borderTop: "1px solid #ffffff1a",
            }}
            fullWidth
            size="large"
            onClick={() => setCollapsed((prev) => !prev)}
          >
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </Button>
        </Drawer>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          top: "64px",
          left: "0px",
          borderRadius: "0 6px 6px 0",
          bgcolor: "secondary.main",
          zIndex: 1199,
          width: "36px",
        }}
      >
        <IconButton
          sx={{ color: "#fff", width: "36px" }}
          onClick={() => setOpened((prev) => !prev)}
        >
          <MenuRounded />
        </IconButton>
      </Box>
    </>
  );
}
