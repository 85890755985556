import { Controller, useForm } from "@pankod/refine-react-hook-form";
import {
  Edit,
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
} from "@pankod/refine-mui";
import { useSelect } from "@pankod/refine-core";

import MultiSelect from "../../components/MultiSelect";

export default function GameEdit() {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm();

  const { options } = useSelect({
    resource: "item-types",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          InputLabelProps={{ shrink: true }}
          {...register("name", { required: "Game Name is required" })}
          error={!!errors?.name}
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Game Name"
          name="name"
          autoFocus
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          {...register("starting_currency", {
            required: "Starting Currency is required",
          })}
          error={!!errors?.starting_currency}
          helperText={errors.starting_currency?.message}
          margin="normal"
          required
          fullWidth
          id="starting_currency"
          label="New Player Starting Currency"
          name="starting_currency"
        />
        <Controller
          control={control}
          name="starting_items"
          render={({ field }) => (
            <MultiSelect
              name="New Player Starting Items"
              options={options}
              value={field.value}
              onChange={(val) => {
                field.onChange(val);
              }}
            />
          )}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          {...register("challenges", {
            required: "Challenges required",
          })}
          error={!!errors?.challenges}
          helperText={errors.challenges?.message}
          margin="normal"
          required
          fullWidth
          label="Progression Challenges for each player"
          name="challenges"
        />
      </Box>
    </Edit>
  );
}
