import {
  Box,
  TextField,
  Autocomplete,
  useAutocomplete,
  Grid,
} from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";

import MultiSelect from "../../components/MultiSelect";

export default function Fields({ control, register, errors }) {
  const { autocompleteProps } = useAutocomplete({
    resource: "games",
  });
  const { options: rewards } = useSelect({
    resource: "item-types",
    optionLabel: "name",
    optionValue: "id",
  });
  const { options: loots } = useSelect({
    resource: "loots",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <Controller
        control={control}
        name="game"
        rules={{ required: "Game is required" }}
        render={({ field }) => (
          <Autocomplete
            {...autocompleteProps}
            {...field}
            value={field.value ? field.value : ""}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            getOptionLabel={(item) => {
              return item.name ? item.name : "";
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.id === value.id
            }
            renderInput={(params) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                {...params}
                label="Game"
                margin="normal"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
                required
              />
            )}
          />
        )}
      />
      <TextField
        {...register("name", {
          required: "Name is required",
        })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.name}
        helperText={errors.name?.message}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        autoFocus
      />
      <TextField
        {...register("generate_rewards", {
          required: "Number of generated Daily Rewards is required",
        })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.generate_rewards}
        helperText={errors.generate_rewards?.message}
        type="number"
        margin="normal"
        required
        fullWidth
        id="generate_rewards"
        label="Number of generated Daily Rewards"
        name="generate_rewards"
      />
      <TextField
        {...register("min_currency", {
          required: "A Minimum Currency amount is required",
        })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.min_currency}
        helperText={errors.min_currency?.message}
        type="number"
        margin="normal"
        required
        fullWidth
        id="min_currency"
        label="Min Currency in Reward"
        name="min_currency"
      />
      <TextField
        {...register("max_currency", {
          required: "A Maximum Currency amount is required",
        })}
        InputLabelProps={{ shrink: true }}
        error={!!errors?.max_currency}
        helperText={errors.max_currency?.message}
        type="number"
        margin="normal"
        required
        fullWidth
        id="max_currency"
        label="Max Currency in Reward"
        name="max_currency"
      />
      <Controller
        control={control}
        name="possible_items"
        render={({ field }) => (
          <MultiSelect
            name="Possible Items that can be Daily Rewards"
            options={rewards}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
            }}
          />
        )}
      />
      <Grid item xs={12} container justifyContent="space-evenly" mt={2}>
        <Grid item xs={4}>
          <TextField
            {...register("from", {
              required: "The Daily Reward Validity period is required",
            })}
            InputLabelProps={{ shrink: true }}
            error={!!errors?.from}
            helperText={errors.from?.message}
            type="date"
            margin="normal"
            required
            fullWidth
            id="from"
            label="Reward valid from"
            name="from"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            {...register("to", {
              required: "The Daily Reward Validity period is required",
            })}
            InputLabelProps={{ shrink: true }}
            error={!!errors?.to}
            helperText={errors.to?.message}
            type="date"
            margin="normal"
            required
            fullWidth
            id="to"
            label="Reward valid to"
            name="to"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
